// -----------------------------------------------------------------------------
// This file contains all application-wide mixins.
// -----------------------------------------------------------------------------
@use 'variables';
@use '../layout/grid-mixins';
@use '../base/breakpoints';
@use './colors';

$grey: adjust-color(colors.$grey, $alpha: -0.8);

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin reset-paragraph {
  padding: 0;
  margin: 0;
}

@mixin reset-header {
  @include reset-paragraph;

  font-size: 1rem;
  font-weight: normal;
}

@mixin reset-icon-button-ripple {
  --ino-icon-button-background-active-color: transparent;
}

@mixin details-gap($row: 0, $column: 0) {

  $inner-content: map-get(variables.$details-layout-defaults,
      'content',
      'inner-content'
    );

  @if $row * $column > 0 {
    @include grid-mixins.gap($row, $column);
  }

  @else {
    @include grid-mixins.gap(map-get($inner-content, 'grid-gap-row'),
      map-get($inner-content, 'grid-gap-column'));
  }
}

@mixin details-content($padding-left: null, $padding-right: null) {

  $content: map-get(variables.$details-layout-defaults, 'content');

  @if $padding-left ==null and $padding-right ==null {
    padding-left: map-get($content, 'padding-left');
    padding-right: map-get($content, 'padding-right');
  }

  @else {
    @if $padding-left !=null {
      padding-left: $padding-left;
    }

    @if $padding-right !=null {
      padding-right: $padding-right;
    }
  }
}

@mixin details-container-grid($tab-list-width: null) {
  display: grid;

  @if $tab-list-width !=null {
    grid-template-columns: $tab-list-width auto;
  }

  @else {
    grid-template-columns: map-get(variables.$details-layout-defaults,
        'container',
        'grid-columns'
      );
  }
}

@mixin avatar-label {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 6px;
  min-width: 100px;
}

@mixin hidden {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  height: 0;
}

@mixin display($display: block) {
  display: $display;
}

@mixin detail-item-label {
  color: colors.$grey;
  font-size: 0.8rem;
  letter-spacing: 0.25px;
  min-height: min-content;
}

@mixin employee-avatar-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin avatar-selection-card-container {
  --ino-card-border-thickness: 0;

  background-color: #{colors.$white};
  width: fit-content;
  box-shadow: 0 2px 5px $grey;
}

@mixin avatar-selection-grid-container {
  display: grid;
  grid-template-columns: minmax(min-content, auto) 3px minmax(min-content, auto);
}

@mixin avatar-selection-selection-section {
  min-width: 150px;
}

@mixin avatar-selection-seperator-line {
  background-color: $grey;
  width: 1px;
}