/* You can add global styles to this file, and also import other style files */
@use 'app/styles/main';

/**
* CAUTION!: Do not use Bootstrap anywhere 
* Bootstrap is only integrated temporary for rating input (opportunity-detail)
* Remove  imports if ngb-rating is not used anymore
*/
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';
