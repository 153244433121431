@function contains($list, $search) {
  @return index($list, $search) != null;
}

@mixin grid($rows: 0, $cols: 0, $size: minmax(0, 1fr)) {
  display: grid;
  height: 100%;

  @if $rows * $cols > 0 {
    grid: repeat($rows, $size) / repeat($cols, $size);
  } @else if $rows > 0 {
    grid-template-rows: repeat($rows, $size);
  } @else {
    grid-template-columns: repeat($cols, $size);
  }
}

@mixin gap($row-gap: 0, $col-gap: 0) {
  @if $row-gap == $col-gap and $row-gap > 0 {
    gap: $row-gap;
  } @else {
    @if $row-gap > 0 {
      row-gap: $row-gap;
    }
    @if $col-gap > 0 {
      column-gap: $col-gap;
    }
  }
}

// https://css-tricks.com/snippets/css/complete-guide-grid/#justify-items
$item-alignments: start end center stretch;
@mixin items-alignment($justify, $align: null) {
  @if $justify == $align and contains($item-alignments, $justify) {
    place-items: $justify;
  } @else {
    @if contains($item-alignments, $justify) {
      justify-items: $justify;
    }
    @if contains($item-alignments, $align) {
      align-items: $align;
    }
  }
}

// https://css-tricks.com/snippets/css/complete-guide-grid/#justify-content
$content-alignments: start end center stretch space-around space-between
  space-evenly;
@mixin content-alignment($justify, $align) {
  @if $justify == $align and contains($content-alignments, $justify) {
    place-content: $justify;
  } @else {
    @if contains($content-alignments, $justify) {
      justify-content: $justify;
    }
    @if contains($content-alignments, $align) {
      align-content: $align;
    }
  }
}

// https://css-tricks.com/snippets/css/complete-guide-grid/#prop-align-self
$item-alignments: start end center stretch;
@mixin items-self-alignment($justify, $align: null) {
  @if $justify == $align and contains($item-alignments, $justify) {
    place-self: $justify;
  } @else {
    @if contains($item-alignments, $justify) {
      justify-self: $justify;
    }
    @if contains($item-alignments, $align) {
      align-self: $align;
    }
  }
}

@mixin inherit-cols() {
  display: grid;
  grid-template-columns: inherit;
}
