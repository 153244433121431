// -----------------------------------------------------------------------------
// All the CSS declarations, hacks and things we are not proud of.
// -----------------------------------------------------------------------------

// Max-Width of popover is set inline
.header ino-popover .tippy-box {
  max-width: 500px !important;
}

// Overwrite avatar placeholder fontsize for assignments
.opportunity-assignment-card__content .avatar-img-placeholder {
  font-size: 0.7rem !important;
}

.tippy-content {
  padding: 8px;
}
