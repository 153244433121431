// -----------------------------------------------------------------------------
// This file contains all application-wide colors.
// -----------------------------------------------------------------------------

$black: #000;
$white: #fff;

// Elements colors
// https://elements.inovex.de/dist/latest/storybook/?path=/story/docs-styleguide--colors
$inovex: #003c7e;

$primary: #3d40f5;
$primary-light: #5d60f7;
$primary-dark: #0d10f3;
$primary-contrast: #fff;

$secondary: #9ccd00;
$secondary-light: #bbd962;
$secondary-dark: #93bf00;
$secondary-contrast: #fff;
$secondary-box-shadow: rgba(156, 205, 0, 0.4);

$success: #93bf00;
$warning: #ffde03;
$warning-dark: #ed8908;
$warning-dark-shadow: rgba(237, 137, 8, 0.4);
$error: #eb003b;
$error-light: #edb3b3;
$error-very-light: #f3c6c6;

$light: #c1c1c1;
$dark: #777;

// ToDo: Not part of elements
$grey: #74758b;
$middle-grey: #d9d9d9;
$light-grey: #f8f9fb;

$dark-blue: #051c59;
$blue: #1316f3;
$light-blue: #ebebfe;
$lighter-blue: #f4f2ff;

$active-color: #30ce00;
$active-color-bg: #efffea;

$light-green: #eaf3cf;

$mint: #c7ffe3;
$light-mint: #eafff4;
$dark-mint: scale-color($mint, $lightness: -20%);

$proposal-bg-color: #dddefd;

// Filter Node colors
$filter-node-line-color: #7769ff;
$filter-node-bg-level-zero: $white;
$filter-node-bg-level-one: rgba(202, 192, 255, 0.18);
$filter-node-bg-level-two: #d4ccff65;
$filter-node-border-color: #d4ccff;

$skills-bg-color: #7df481;
