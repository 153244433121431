@use '../abstracts/variables';
@use '../abstracts/colors';

// -----------------------------------------------------------------------------
// This file contains typographic styles.
// -----------------------------------------------------------------------------

html {
  font-size: 100%;
}

body {
  color: variables.$text-color;
  font-family: variables.$text-font-stack;
}

h1 {
  color: variables.$text-color;
  font-size: 2rem;
}

p,
data {
  color: variables.$text-color-light;
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: colors.$dark-blue;

  &.highlight,
  &:hover {
    color: colors.$primary;
  }
}

ino-markdown-editor p {
  color: variables.$text-color;
}

%markdown-headline {
  color: colors.$dark;
  font-size: 1.5rem;
}

ino-markdown-editor h2 {
  @extend %markdown-headline;

  font-weight: normal;
}

ino-markdown-editor h1 {
  @extend %markdown-headline;

  font-weight: bold;
}

[class$='--italic'] {
  font-style: italic;
}

.invalid {
  font-size: 12px;
  color: colors.$error;
}

.loading-text::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 600ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
