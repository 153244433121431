// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html {
  box-sizing: border-box;
}

body {
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
