// -----------------------------------------------------------------------------
// This file contains all elements styles overwrites.
// -----------------------------------------------------------------------------
@use 'src/app/styles/abstracts/variables';
@use 'src/app/styles/abstracts/colors';

ino-tab .mdc-tab {
  text-transform: none;
}

ino-menu .mdc-menu {
  transform-origin: right right;
}

ino-tab-bar .mdc-tab {
  height: variables.$filter-sidebar-toggle-btn-height + 2px;
}

ino-control-item .mdc-deprecated-list-item__ripple {
  &::before,
  &::after {
    display: none;
  }
}

ino-card.add-need-form .ino-card__footer,
ino-card.add-need-form .ino-card__content {
  padding: 0 20px;
}

ino-card.add-need-form .ino-card__header {
  padding: 20px 20px 0;
}

ino-card.opportunity-assignment-card .ino-card__content {
  padding: 20px;
}

td.contact-persons-row {
  padding: 0;
}

.utilization__percentage-input .mdc-text-field__affix.mdc-text-field__affix--suffix {
  padding-left: 4px;
  margin-right: 6px;
}

ino-input.small {
  .mdc-text-field--filled {
    height: 32px;
  }

  .mdc-text-field--filled .mdc-text-field__input {
    padding-top: 0;
  }

  .mdc-text-field__icon {
    padding-top: 8px;
  }
}

ino-select.small {
  .mdc-select__anchor.mdc-ripple-upgraded {
    height: 32px;
  }

  .mdc-select__selected-text,
  .mdc-select__dropdown-icon {
    margin-top: 0;
    padding-top: 4px;
  }

  .mdc-select__selected-text:empty {
    padding-top: 15px;
  }
}

ino-select.no-line .mdc-select__anchor .mdc-line-ripple::before {
  display: none;
}

ias-autocomplete-multi.small,
ias-autocomplete-single.small ino-input {
  .mdc-text-field--filled {
    height: 32px;
  }

  .mdc-text-field--filled .mdc-text-field__input {
    padding-top: 0;
  }

  .mdc-text-field__input {
    height: 26px;
  }

  .mdc-text-field__icon {
    padding-top: 8px;
  }
}

ino-card.app-card .ino-card__content {
  padding: 0;
  height: 100%;
  border-radius: inherit;
}

.notifications-container ino-snackbar .mdc-snackbar {
  position: relative;
}

.multi-select__chip-set ino-chip .mdc-evolution-chip {
  height: 24px;
}

.multi-select__chip-set ino-chip .ino-chip-label {
  font-size: 11px;
}

.multi-select__chip-set ino-chip ino-icon.multi-select__icon {
  --ino-icon-width: 9px;
  --ino-icon-height: 9px;
}

.multi-select__chip-set ino-chip .mdc-evolution-chip__icon.mdc-evolution-chip__icon--primary.ino-chip-leading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}

.multi-select__chip-set--small {
  ino-chip {
    --ino-icon-width: 8px;
    --ino-icon-height: 8px;
  }

  ino-chip .mdc-evolution-chip {
    height: 16px;
    border-radius: 12px;
  }

  ino-chip.multi-select__add-chip button.mdc-evolution-chip__action {
    overflow-y: hidden;
  }

  ino-chip .ino-chip-label {
    font-size: 10px;
    line-height: 11px;
  }
}

.multi-select__chip-set.multi-select__chip-set--add-chip-container-no-button-label
  .mdc-evolution-chip.mdc-evolution-chip--with-primary-graphic
  .mdc-evolution-chip__action--primary {
  padding-right: 0;
}

.multi-select__chip-set .ino-chip-trailing-icon ino-icon.ino-chip-close-icon {
  --ino-icon-width: 10px;
  --ino-icon-height: 10px;
}

.multi-select__add-chip--error span.mdc-evolution-chip.ino-chip-container {
  background-color: colors.$error-light;
}

ino-list-item.link-form__list-item--has-link .mdc-deprecated-list-item__text .mdc-deprecated-list-item__secondary-text {
  text-decoration: underline;

  &:hover {
    color: #{colors.$primary};
  }
}

// overwrite hover style in read mode
ino-table.contact-persons__table
  tr.contact-persons__table-row.contact-persons__table-row--no-action:hover
  > td.contact-persons-row {
  border-color: transparent !important;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.forecast ino-table ino-table-header-cell:not(:first-child) .mdc-data-table__header-cell-wrapper {
  justify-content: flex-end;
  margin-right: 0;
}

ino-card.assignment-form .ino-card__content {
  padding: 5px 10px;
}

.staffing-form__assignment ino-input .mdc-text-field__affix--suffix {
  padding-left: 0;
  margin-right: 8px;
}

// Adjust success chip to use avatar's mint color
ino-chip.header__opportunity-state.ino-chip--color-scheme-success.ino-chip--solid {
  .mdc-evolution-chip__text-label {
    color: colors.$dark-blue !important;
  }

  .ino-chip-container {
    background-color: colors.$mint;
  }
}

ino-chip.header__opportunity-state {
  button {
    pointer-events: none;
  }
}

// fix monthpicker where selected month is the current month
.flatpickr-calendar .flatpickr-monthSelect-months .flatpickr-monthSelect-month.today.selected {
  color: #fff;
}

ino-segment-group.small {
  transform: scale(0.7) translateX(-1.8rem);
}

ino-segment-group.dark {
  background-color: colors.$white;

  button.button--active {
    color: colors.$white;
    background-color: colors.$primary;
  }
}
