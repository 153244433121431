// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
@use '../abstracts/mixins';
@use './breakpoints';

// Main content container
.container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.hidden {
  @include mixins.hidden;
}

.reset-paragraph {
  @include mixins.reset-paragraph;
}

.ellipsis-on-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

[class$='--pointer'] {
  cursor: pointer;
}

@each $name, $size in breakpoints.$breakpoints {
  .hidden-#{$name} {
    @include breakpoints.media('<=' + $name) {
      @include mixins.hidden;
    }
  }
}

@each $name, $size in breakpoints.$breakpoints {
  .hidden-#{$name} {
    @include breakpoints.media('<=' + $name) {
      @include mixins.hidden;
    }
  }
}

.none {
  @include mixins.display(none);
}

@each $name, $size in breakpoints.$breakpoints {
  .none-#{$name} {
    @include breakpoints.media('<=' + $name) {
      @include mixins.display(none);
    }
  }
}
