@use 'grid-mixins';

// -----------------------------------------------------------------------------
// This file contains all layout styles.
// leaning on: https://tailwindcss.com/docs/grid-column
// -----------------------------------------------------------------------------
$column-count: 5;
$row-count: 3;
$gap-count: 6;
$gap-step-size: 0.5rem;

.grid {
  display: grid;
}

.grid__inherit {
  display: grid;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
}

.grid__inherit-cols {
  display: grid;
  grid-template-columns: inherit;
}

// generate grid gutter classes
@for $rows from 1 through $row-count {
  @for $cols from 1 through $column-count {

    $class-name: 'grid-' + $rows + 'x' + $cols;
    [class*='#{$class-name}'] {
      @include grid-mixins.grid($rows, $cols);
    }
  }
}

// generate grid row classes
@for $i from 1 through $row-count {
  .row-span-#{$i} {
    grid-row: span $i / span $i;
  }
  .row-start-#{$i} {
    grid-row-start: $i;
  }
}

// generate grid column classes
@for $i from 1 through $column-count {
  .col-span-#{$i} {
    grid-column: span $i / span $i;
  }
  .col-start-#{$i} {
    grid-column-start: $i;
  }
}

// generate grid gap classes
@for $i from 1 through $gap-count {

  $gap-size: $i * $gap-step-size;
  .gap-#{$i} {
    gap: $gap-size;
  }
  .gap-x-#{$i} {
    column-gap: $gap-size;
  }
  .gap-y-#{$i} {
    row-gap: $gap-size;
  }
}

// generate item alignment classes
@each $value in grid-mixins.$item-alignments {
  .place-items-#{$value} {
    @include grid-mixins.items-alignment($value, $value);
  }
  .items-#{$value} {
    @include grid-mixins.items-alignment(null, $value);
  }
  .justify-items-#{$value} {
    @include grid-mixins.items-alignment($value);
  }
}

// generate item-self alignment classes
@each $value in grid-mixins.$item-alignments {
  .place-self-#{$value} {
    @include grid-mixins.items-self-alignment($value, $value);
  }
  .self-#{$value} {
    @include grid-mixins.items-self-alignment(null, $value);
  }
  .justify-self-#{$value} {
    @include grid-mixins.items-self-alignment($value);
  }
}
