@use 'colors';

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Regular font family
$text-font-stack: ('Lato', helvetica, verdana, sans-serif);

// Copy text color
$text-color: colors.$black;
$text-color-light: colors.$grey;

// Nav-drawer
$nav-drawer-width-open: 210px;
$nav-drawer-width-closed: 72px; // Only used for layout calculation

// Filter-sidebar
$filter-sidebar-width: 450px;
$filter-sidebar-width-mobile: 350px;
$filter-sidebar-toggle-btn-height: 36px;

// Search-bar properties
$search-bar-height: 100px;
$search-bar-margin: 30px;
$search-bar-min-width: 698px;

// Opportunity List
$circle-transition-duration: 1s;

// Details view layout defaults
$details-layout-defaults: (
  'container': (
    'grid-columns': 65px auto,
  ),
  'content': (
    'padding-left': 1rem,
    'padding-right': 1rem,
    'header-min-height': 150px,
    'inner-content': (
      'grid-gap-row': 1rem,
      'grid-gap-column': 1rem,
    ),
  ),
);
