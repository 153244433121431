/**
 * The style structure of this project is insipred by https://sass-guidelin.es/
 * which uses a SMACSS oriented architecture and Block Element Modifier (BEM)-like
 * naming conventions (https://cssguidelin.es/).
 *
*/
@charset 'utf-8';

/* ------------------------------------*\
  #Abstracts
\*------------------------------------ */
@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'abstracts/variables';

/* ------------------------------------*\
  #Base
\*------------------------------------ */
@use 'base/animations';
@use 'base/base';
@use 'base/helper';
@use 'base/normalize';
@use 'base/reset';
@use 'base/typography';

/* ------------------------------------*\
  #Layout
\*------------------------------------ */
@use 'layout/flex';
@use 'layout/forms';
@use 'layout/grid';

/* ------------------------------------*\
  #Vendors
\*------------------------------------ */
@use 'vendors/elements';

/* ------------------------------------*\
  #Shamefile
\*------------------------------------ */
@use 'shame';

/* ------------------------------------*\
  #Tailwind CSS
\*------------------------------------ */
@tailwind base;
@tailwind components;
@tailwind utilities;
