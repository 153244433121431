@use 'flex-mixins';

// -----------------------------------------------------------------------------
// This file contains reusable flex styles.
// --------

.flex--row {
  display: flex;
  flex-direction: row;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

$justify-content: center flex-end flex-start space-around space-between;
@each $value in $justify-content {
  .justify-content-#{$value} {
    @include flex-mixins.justify-content($value);
  }
}
